<template>
  <div ref="size" style="text-align: center">
    <div style="height: 100px"></div>
    <v-container >
      <v-flex d-flex justify-center mt-auto>
        <h4><b>Bifidobacterium</b></h4>
      </v-flex>
      <v-flex d-flex justify-center mt-auto>
        Genus 나이별 다양성
      </v-flex>
    </v-container>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="bifiChartData" ref="bifiChart" :initOptions="chartSize"/>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
      </v-flex>
    </v-layout>
    <br><br><br>
    <br><br><br>

    <v-container >
      <v-flex d-flex justify-center mt-auto>
        <h4><b>Bifidobacterium</b></h4>
      </v-flex>
      <v-flex d-flex justify-center mt-auto>
        Species 별 분석
      </v-flex>
    </v-container>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="bifiSpeciesChart" :initOptions="chartSize"/>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
      </v-flex>
    </v-layout>
    <br><br><br>
    <br><br><br>


    <v-container >
      <v-flex d-flex justify-center mt-auto>
        <h4><b>Lactobacillus</b></h4>
      </v-flex>
      <v-flex d-flex justify-center mt-auto>
        Genus 나이별 다양성
      </v-flex>
    </v-container>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="lactoGenusChartData"  :init-options="chartSize" />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
      </v-flex>
    </v-layout>
    <br><br><br>
    <br><br><br>


    <v-container >
      <v-flex d-flex justify-center mt-auto>
        <h4><b>Lactobacillus</b></h4>
      </v-flex>
      <v-flex d-flex justify-center mt-auto>
        Species 별 분석
      </v-flex>
    </v-container>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="lactoSpeciesChart" :initOptions="chartSize"/>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
      </v-flex>
    </v-layout>
    <br><br><br>
    <br><br><br>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
  },
  name: 'LactoDetailDash.vue',
  data() {
    return {
      active : false,
      chartSize : {
        width: window.innerWidth,
        height: 500
      },
    }
  },
  methods: {
    ...mapActions(['lactoDetail']),

    chartResize() {
      try {
        let resizeWidth = this.$refs.size.clientWidth;
        console.log("resize ~ " , this.$refs.size);
        this.chartSize.width = resizeWidth;
        //resizeWidth = (resizeWidth > 1300 )?1300:resizeWidth;
        /*

                  this.polar.init.width = resizeWidth;
                  this.totalLegendPhylum.init.width = resizeWidth;
                  this.total.init.width = resizeWidth;
                  this.totalPhylum.init.width = resizeWidth;
        */
        console.log("resize ~ " , resizeWidth, window.innerWidth)
        this.chartSize.width = resizeWidth;
      } catch (e) {
        console.log("resize", e);
      }
    },
/*
    async updateChart(){
      console.log("update chart");
      let lactoDetailData = await this.lactoDetail();
      this.bifiChartData = lactoDetailData.diversity.bifi;
      this.lactoGenusChartData = lactoDetailData.diversity.lacto;
      this.bifiSpeciesChart = lactoDetailData.speciesDetail.bifi;
      this.lactoSpeciesChart = lactoDetailData.speciesDetail.lacto;

    },
*/
    async reload(){
      this.chartSize = {
        width: window.innerWidth,
        height: 500
      }
    }
  },
  mounted () {
    setTimeout(this.chartResize, 100)
    window.addEventListener('resize', this.chartResize);
    //this.updateChart()
  },
  updated () {
  },
  computed: {
    code : {
      get() {
        console.log("call lactoDetail Get Code:", this.$store.state.code)
        //this.updateChart();
        return this.$store.state.code
      }
    },
/*
    overlayLoading: function(){
     return this.$store.state.overlayLoading;
    },
*/
    lactoSpeciesChart: function(){
     return this.$store.state.lactoDetail.diversity.bifi;
    },
    bifiSpeciesChart: function() {
      return  this.$store.state.lactoDetail.diversity.lacto;
    },
    bifiChartData: function() {
      return this.$store.state.lactoDetail.speciesDetail.bifi;
    },
    lactoGenusChartData: function() {

      return this.$store.state.lactoDetail.speciesDetail.lacto;
    }

  }
}
</script>

<style scoped>

</style>